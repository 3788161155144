.rating {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
}

.rating>input {
    display: none
}

.rating>label {
    position: relative;
    /* width: 1em; */
    font-size: 32px;
    color: #FFD600;
    cursor: pointer
}

.rating>label::before {
    content: "\2605";
    position: absolute;
    opacity: 0
}

.rating>label:hover:before,
.rating>label:hover~label:before {
    opacity: 1 !important
}

.rating>input:checked~label:before {
    opacity: 1
}

.rating:hover>input:checked~label:before {
    opacity: 0.4
}

.noselect {
    -webkit-touch-callout: none;
      -webkit-user-select: none;
       -khtml-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
          -webkit-tap-highlight-color: transparent;
}
  
.rainbow {
      width: 150px;
      height: 30px;
      cursor: pointer;
      background: #6dd6d1;
      border: none;
      border-radius: 10px;
      box-shadow: 0px 10px 25px #57aba7, 0px -10px 25px #a6fffa, inset 0px -5px 10px #57aba7, inset 0px 5px 10px #a6fffa;
      color: white;
      font-size: 13px;
      transition: 500ms;
      /* animation: hueRotation 2s linear infinite; */
}
  
@keyframes hueRotation {
      to {filter: hue-rotate(360deg);}
} 
  